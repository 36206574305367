<template>
<vx-card title="" class="mt-3">
    <div class="flex items-center justify-center mb-2 mt-1 ml-auto" v-if="userData.photoURL">
        <avatar style="margin-left:5px;" v-if="userData.photoURL.split('/')[userData.photoURL.split('/').length-1] === 'default_dp.png'" class="border-2 border-solid border-white mr-2 my-1" :username="userData.displayName" :size="42" />

        <vs-avatar size="100px" :src="userData.photoURL" v-else />
    </div>
    <!-- USER BIO -->
    <div class="user-bio">
        <h5 class="text-center">{{ userData.displayName }}</h5>
        <p class="text-center">{{ userData.studyStatus }}
            <feather-icon v-if="userData.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-4 w-4 bg-success rounded-full text-white"></feather-icon>
        </p>

    </div>
    <!-- <vx-tooltip text="" ref='clipboard'>
        <p class="text-center" id='wallet_address' @mouseover="tooltipTitle" @click='copyToClipBoard()'><b>{{walletAddress}}</b></p>
    </vx-tooltip> -->
<!-- 
    <div class="mt-5">
        <span v-html="userData.about"></span>
    </div> -->

   

    <div class="my-2">
        <p>Has {{ followersLength }} followers</p>
        <p>Currently following {{ followingLength }} people</p>
    </div>

    <a @click="goToProfile" class="cursor-pointer text-primary">Go To My Profile</a>

</vx-card>
</template>

<script>
import {
    API,
    graphqlOperation
} from '@aws-amplify/api'
import {
    getFollowers,
    getFollowing
} from '@/graphql/queries'
import Avatar from 'vue-avatar'

export default {
    name: 'AboutUser',
    data() {
        return {
            followingLength: 0,
            followersLength: 0
        }
    },
    components: {
        Avatar
    },
    computed: {
        userData() {
            return this.$store.state.auth.userData
        },
        walletAddress() {
            return this.$store.state.auth.walletAccount
        }

    },
    methods: {
        goToProfile() {
            this.$router.push(`/profile/${this.userData.name}`)
        },
        copyToClipBoard() {
            const walletAccounts = localStorage.getItem("walletAccounts");
            const wallet = JSON.parse(walletAccounts)[0]
            navigator.clipboard.writeText(wallet);
            this.$refs.clipboard.text = 'Wallet address copied'
        },
        tooltipTitle() {
            this.$refs.clipboard.text = "Copy to clipboard"
        },
        setWalletAddress() {
            const walletAccounts = localStorage.getItem("walletAccounts");
            if (walletAccounts == undefined || walletAccounts == null) {
                return ''
            } else {
                const wallet = JSON.parse(walletAccounts)[0]
                const strFirstThree = wallet.substring(0, 5);
                const strlastThree = wallet.substr(wallet.length - 5);
                const walletNumber = strFirstThree + '.....' + strlastThree
                this.$store.commit("auth/SET_WALLET_ACCOUNT", walletNumber);
            }
        }
    },
    created() {
        this.setWalletAddress();
        API.graphql(graphqlOperation(getFollowers, {
            user: this.$store.state.auth.userData.name
        })).then(res => {
            this.followersLength = res.data.getFollowers.items.length
        }).catch(err => console.log(err))

        API.graphql(graphqlOperation(getFollowing, {
            follower: this.$store.state.auth.userData.name
        })).then(res => {
            this.followingLength = res.data.getFollowing.items.length
        }).catch(err => console.log(err))

    }
}
</script>
