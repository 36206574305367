<template>
  <div>
    <!-- <div id="v-step-0">A DOM element on your page. The first step will pop on this element because its ID is 'v-step-0'.</div>
    <div class="v-step-1">A DOM element on your page. The second step will pop on this element because its ID is 'v-step-1'.</div>
    <div data-v-step="2">A DOM element on your page. The third and final step will pop on this element because its ID is 'v-step-2'.</div>-->

    <v-tour name="myTour" :steps="steps" :callbacks="myCallbacks" ></v-tour> 
  </div>
</template>

<script>
  let self = {};
  export default {
    name: 'my-tour',
    data () {
      return {
        myCallbacks: {
          onStop: function () {
            self.updateIntro();
          },
        },
        steps: [
          {
            target: '.vt-step-1',  // We're using document.querySelector() under the hood
            // header: {
            //   title: 'Get Started',
            // },
            content: `Welcome to MyPals! We're thrilled to have you join us. 🌟<br> Using our AI tools, convert your thoughts into funny memes!`,
            params: {
              placement: 'top'
            }
          },
          {
            target: '.vtm-step-2',
            content: 'Its so easy! Just write your thoughts, and watch them convert into beautiful memes in seconds.',
            params: {
              placement: 'top'
            }
          },
         // {
         //   target: '.vtm-step-3',
         //   content: 'SimilaClick on List Now button to list your NFTs in our marketplace.',
         //   params: {
         //     placement: 'top'
         //   }
         // },

          {
            target: '.vtm-step-4',
            content: 'We cant wait to see how you use this powerful tool to express your ideas and make new friends! Let us know if you have any questions. 😊😊😊',
            params: {
              placement: 'top'
            }
          }
        ]
      }
    },
    created () {
      self = this;
    },
    mounted: function () {
      this.$tours['myTour'].start()
    },
    methods: {
      updateIntro () {
        this.$store.dispatch('auth/updateIntro')      
      }
    }
  }
</script>