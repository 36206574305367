<template>
<vx-card class="mt-3 post-card mt-xs-mobile">
   
    <div class="post-header">
        <div class="vx-row mb-0 mb-md-3">
            <div class="vx-col w-full">
                <div class="flex items-center">
                    <input v-if='this.prevImages.length ==0' type="text" class="w-full image-generator" v-model="image_text" placeholder="Type your thoughts to generate art!">

                    <div class="ml-3" v-if='this.prevImages.length ==0'>
                        <feather-icon @click='speechToText' v-if='speakNow' icon="MicIcon" class="cursor-pointer mic-on" svgClasses="w-6 h-6"></feather-icon>
                        <feather-icon @click='speechToTextStop' v-if='!speakNow' icon="MicOffIcon" class="cursor-pointer mic-off" svgClasses="w-6 h-6"></feather-icon>
                    </div>
                </div>
            </div>

            <div class="vx-col w-full" v-if='this.prevImages.length ==0'>
                <div class="carousel-example">
                    <swiper :options="swiperOption" :dir="$vs.rtl ? 'rtl' : 'ltr'" :key="$vs.rtl">
                        <swiper-slide v-for="(tag, index) in tags" :key="tag.name" class="ss-width">
                            <span class='mb-2' @click='setTag(tag.name)'>
                                <b-link class="art-tag">{{tag.name}}</b-link>
                            </span>
                        </swiper-slide>
                    </swiper>
                </div>

            </div>
        </div>

        <div class="vx-row">
            <div class="vx-col sm:w-1/2 w-full mb-4" v-if='this.prevImages.length !=0'>
                <vs-input class="w-full" placeholder='NFT Name' v-model="nftName" />
            </div>
            <div class="vx-col sm:w-1/2 w-full mb-4" v-if='this.prevImages.length !=0'>
                <v-select placeholder='Select NFT Category' v-model="nftCategory" :options="categories" multiple :dir="$vs.rtl ? 'rtl' : 'ltr'" />
            </div>

            <div class="vx-col w-full" v-if='this.prevImages.length !=0'>
                <vs-textarea v-model="nftDescription" placeholder='NFT Description' class="mb-0" rows="3" />
            </div>
        </div>

        <div class="vx-row items-center">

        </div>
    </div>
    <p v-if="video !== ''">{{ video.name }}</p>

    <div class="flex flex-wrap mb-4">
        <div class="" :key="index" v-for="(image, index) in prevImages">
            <feather-icon icon="XCircleIcon" class="img-close-button" svgClasses="h-7 w-7 mb-1 text-primary" @click="removeUploadImage(index)"></feather-icon>
            <img :src="image" width="110" height="110" class="object-cover" alt="upload-image" />
        </div>
    </div>

<div class="" v-if='isLoading'>
<div class="loader-nft">
    <div class="inner one"></div>
    <div class="inner two"></div>
    <div class="inner three"></div>
</div>
</div>

    <div class="og-card" v-if="hasOpenGraph">
        <vs-card v-if="!ogLoading" class="cursor-pointer z-50">
            <feather-icon icon="XCircleIcon" class="og-close-button" svgClasses="h-8 w-8 mb-1 text-primary" @click="hasOpenGraph = false"></feather-icon>
            <div class="static og-container" @click="goToWebsite">
                <div>
                    <div slot="media" v-if="openGraphData.ogImage">
                        <img :src="openGraphData.ogImage.url" class="og-img" />
                    </div>
                    <vs-divider />
                    <div>
                        <h5 class="mt-2">{{ openGraphData.ogTitle | truncate(50) }}</h5>
                        <span v-if="openGraphData.ogSiteName">{{ openGraphData.ogSiteName  }}</span>
                        <p class="text-base">{{ openGraphData.ogDescription }}</p>
                    </div>
                </div>
            </div>
        </vs-card>
        <div class="block loading" v-else>
            <div class="loading">
                <div class="effect-1 effects"></div>
                <div class="effect-2 effects"></div>
                <div class="effect-3 effects"></div>
            </div>
        </div>
    </div>

    <div class="flex mt-2">

        <div class="w-full flex items-center">
            <div class="">

                <vs-button type="filled" size="small" class="ai-art-btn" icon-pack="feather" icon="icon-zap" :disabled="!validateGenerate" @click='generateImages'><span class="">Create AI Art</span></vs-button>
            </div>
            <!--
            <div @mouseover="displayVideoUploadCard" @mouseleave="hideVideoUploadCard" @mousewheel="hideVideoUploadCard">
                <div class="img-btn ml-2">
                    <vs-button color="dark" type="line" icon="image" :disabled="hasOpenGraph">
                        <input ref="videoInput" type="file" accept="video/*" @change="onVideoChange" />
                    </vs-button>
                </div>
                <template>
                    <vs-card class="video-recordvideo upload-video-image hoverCard" ref="videoUploadCard">
                        <div class="ml-2 text-base">
                            <div class="mt-2">
                                <div class="flex items-center cursor-pointer" @click="uploadImage">
                                    <feather-icon icon="ImageIcon" class="mr-3" svgClasses="h-4 w-4" />
                                    <span>Image</span>
                                </div>
                            </div>
                            <div class="mt-2">
                                <div class="flex items-center cursor-pointer" @click="uploadVideo">
                                    <feather-icon icon="FilmIcon" class="mr-3" svgClasses="h-4 w-4" />
                                    <span>Video</span>
                                </div>
                            </div>
                        </div>
                    </vs-card>

                </template>
            </div>
            -->

        </div>
        <!--
        <div class="flex">
            <vs-button @click="onSubmitPost" color="primary" type="filled" size="small" :disabled="prevImages.length ==0 ? mintDisabled=true:false">Mint Now</vs-button>
        </div>
        -->

    </div>

    <!-- Mint-nft popup -->

    <vs-popup class="dalle-image" :title="title" :active.sync="createImagePopUp">
        <div class="vld-parent">
        
            <!-- Generated Images -->

            <div class="vx-row mt-base">
                <div v-for="(image, index) in imageDalle" :key="image.id" @click='selectGenereatedImage(image)' class="vx-col w-1/2 sm:w-1/2 md:w-1/2 xl:1/2">
                    <img :src="image.url" class="rounded mb-5 responsive image-dalle">
                </div>
            </div>

        </div>
    </vs-popup>

</vx-card>
</template>

<script>
import vSelect from "vue-select";
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import 'swiper/dist/css/swiper.min.css'
import {
    swiper,
    // swiperSlide
} from 'vue-awesome-swiper'
// import imageCompression from 'browser-image-compression';
import ProgressBar from './ProgressBar'
import EmojiPickerWrapper from './EmojiPickerWrapper'
import axios from 'axios'
// import {
//     getBase64Strings
// } from 'exif-rotate-js/lib'
// import VideoRecord from './VideoRecord'

// import MyPalsNFT from '@/assets/utils/MyPalsNFT.json';
import ImageUploader from 'vue-image-upload-resize';
import {
    getUser
} from '@/graphql/queries'
import {
    API,
    graphqlOperation
} from '@aws-amplify/api'
// import {
//     listCategorys
// } from "@/graphql/queries";

export default {
    components: {
        "v-select": vSelect,
    },
    props: {
        userImage: String,
        parentType: String,
    },
    data() {
        return {
            input: '',
            image_text: '',
            ai_generated: false,
            art_type: '',
            userDetails: '',
            mintTokenId: '',
            nftCategory: '',
            nftName: '',
            verify: false,
            search: '',
            prevImages: [],
            images: [],
            video: '',
            hasOpenGraph: false,
            openGraphData: {},
            crawledUrl: '',
            ogLoading: false,
            videoRecordPopupActive: false,
            recordedData: null,
            videoTitle: '',
            isValidVideoTitle: true,
            is_loading: false,

            title: '',
            mintSupply: '',
            isMinting: false,

            // myPalsNFTAPI: process.env.VUE_APP_MintNFTAPI,
            if_image: false,

            // Image Uploader New
            hasImage: false,
            image: null,
            createImagePopUp: false,
            dalle_image: [],
            imageDalle: [],
            Dalleimages: [],
            converted_image: '',
            speakNow: true,
            speechRecognition: new webkitSpeechRecognition(),
            filename: '',
            post_category: [],

            isLoading: false,
            fullPage: false,
            loader: 'dots',
            color: '#dc123e',
            useSlot: true,
            timeout: 3000, //ms
            categories: [],
            nftDescription: '',
            dalleImage: '',
            account: '',
            dalle_file: '',
            cat: [],
            attributes: [],
            tags: [{
                    name: ' Mural Painting',

                },
                {
                    name: ' Water Colour',
                },
                {
                    name: ' Pencil Drawing',
                },
                {
                    name: ' Abstract Drawing',
                },
                {
                    name: ' Realism Painting'
                },
            ],

            settings: {
                maxScrollbarLength: 60,
                wheelSpeed: .60
            },

            swiperOption: {
                slidesPerView: 5,
                spaceBetween: 10,
                breakpoints: {
                    1024: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    },
                    768: {
                        slidesPerView: 5,
                        spaceBetween: 10
                    },
                    640: {
                        slidesPerView: 3,
                        spaceBetween: 10
                    }
                }
            }

        }
    },

    computed: {

        // Checking if the user is a partner.
        isPartner() {
            return this.$store.state.auth.userData.isPartner
        },
        // Checking if the user is a partner or not. If the user is not a partner, it will check if the
        // parentType is coursefeed. If it is, it will return true. If it is not, it will return false.
        // If the user is a partner, it will return the userData.isPartner.
        showVideo() {
            if (!this.$store.state.auth.userData.isPartner) {
                if (this.$props.parentType == 'coursefeed') {
                    return true;
                } else {
                    return false;
                }
            } else {
                return this.$store.state.auth.userData.isPartner
            }
        },
        // Checking if the input is empty and if there are any errors.
        validateForm() {
            return !this.errors.any() && this.input !== ''
        },
        validateGenerate() {
            return this.image_text.length !== 0
        },
        // Returning the displayName of the user.
        userDisplayName() {
            return this.$store.state.auth.userData.displayName
        },
        scrollbarTag() {
            return this.$store.state.is_touch_device ? 'div' : 'VuePerfectScrollbar'
        },
    },
    mounted() {
        let recaptchaScript = document.createElement('script')
        recaptchaScript.setAttribute('src', 'https://cdnjs.cloudflare.com/ajax/libs/exif-js/2.3.0/exif.js')
        document.head.appendChild(recaptchaScript)
    },
    created() {
        // this.fetchCategory();
    },

    methods: {

        setTag(val) {

            for (var x = 0; x < this.tags.length; x++) {
                if (this.dalle_image.includes(this.tags[x].name)) {

                    this.dalleImage = this.dalle_image.replace(this.tags[x].name, "")
                    this.art_type = ''
                }
            }
            if (this.dalleImage == "") {
                this.dalle_image = this.dalle_image + val
                this.art_type = val
            } else {
                this.dalle_image = this.dalleImage + val
                this.dalleImage = ''
                this.art_type = val
            }

        },

        // Fetching the data from the database and storing it in the categories array.
        // async fetchCategory() {
        //     try {
        //         const res = await API.graphql(graphqlOperation(listCategorys))
        //         this.categories = res.data.listCategorys.items.map(item => {
        //             return {
        //                 label: item.name,
        //                 val: item.id
        //             }
        //         })
        //     } catch (err) {
        //         console.log('fetchCategory', err)
        //     }
        // },
        // Using the webkitSpeechRecognition API to listen to the user's voice and then it is assigning
        // the result to the variable dalle_image.
        speechToText() {

            let final_transcript = "";

            this.speechRecognition.continuous = true;
            this.speechRecognition.interimResults = true;
            this.speechRecognition.start();
            this.speakNow = false;

            this.speechRecognition.onresult = (event) => {
                let interim_transcript = "";

                for (let i = event.resultIndex; i < event.results.length; ++i) {
                    if (event.results[i].isFinal) {
                        final_transcript += event.results[i][0].transcript;
                    }
                }
                this.dalle_image = final_transcript;

            };
        },

        // Stopping the speech recognition.
        speechToTextStop() {

            this.speechRecognition.stop();
            this.speakNow = true

        },

        // Converting the image to jpg and then pushing it to the images array.
        // async selectGenereatedImage(image) {
        //     this.$vs.loading()
            // const res = await axios.post('https://api.coursepal.app/api/convertFileWebpToJpg', {
            //     image_url: image,
            // });
            // this.converted_image = res.data.data.url
            // console.log(this.converted_image)
            // fetch(image.url)
            //     .then(res => res.blob()) // Gets the response and returns it as a blob
            //     .then(blob => {

                    // const ext = this.converted_image.split(".").pop();
                    // const filename = this.converted_image.split("/").pop();
                    // this.filename = this.converted_image.split("/").pop();
                    // const metadata = {
                    //     type: `image/${'png'}`
                    // };
                    // let file = new File([blob], image.filename, metadata);
                    // console.log(file)

                    // this.dalle_file = file
                    // console.log(this.dalle_file)
                    // this.prevImages.push(this.converted_image)
                    // this.images.push(file)
                    // this.images.splice(0, 1, file)
                    // this.prevImages.splice(0, 1, this.converted_image);

                //     this.$vs.loading.close();
                //     this.createImagePopUp = false;
                //     this.dalle_image = ''
                //     this.image_text=''
                //     this.imageDalle = []
                //     this.postImage()
                // });

        // },

        async generateImages() {
            this.isLoading = true;
            axios
                .post(
                    "https://generate.briks.ai/api/run",
                    //  {
                    //     deployment_id: "158d8ad9-9cca-44d4-ad63-f98651647344",
                    //     inputs: {
                    //         input_text: this.image_text,
                    //     },
                    // },
                    {
                        "deployment_id": "321e2de9-16b8-4b8d-93a7-57701fcacdc4",
                        "inputs": {
                            "input_text": this.image_text
                        }
                    }, {
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidXNlcl8yZlBGMktkZU9rNlFuVVdjOU1nNHlqYjY3aTYiLCJpYXQiOjE3MTQzMTU4NjJ9.yaBV7mJVZU62qkqr47JET2uO5PbkBag-6DpDVKKJTT0",
                        },
                    }
                )
                .then((response) => this.fetchImage(response.data.run_id))
                .catch((error) => console.error("Error:", error));
        },
        async fetchImage(val) {
            let status = 'running'; // Initial status

            while (status !== 'success') {
                try {
                    const response = await fetch("http://13.93.151.146:3000/api/run?run_id=" + val, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoidXNlcl8yZlBGMktkZU9rNlFuVVdjOU1nNHlqYjY3aTYiLCJpYXQiOjE3MTQzMTU4NjJ9.yaBV7mJVZU62qkqr47JET2uO5PbkBag-6DpDVKKJTT0",
                        },
                    });

                    if (!response.ok) {
                        throw new Error("Network response was not ok");
                    }

                    const data = await response.json();
                    status = data.status; // Update status from response

                    // Check if status is 'success', if not, wait for some time before making the next request
                    if (status !== 'success') {
                        await new Promise(resolve => setTimeout(resolve, 1000)); // Delay for 1 second (adjust as needed)

                    }
                    if (status == 'success') {
                        this.isLoading = false;
                        console.log(data.outputs[0].data.images)
                        // this.imageDalle.push(data.outputs[0].data.images)
                        this.imageDalle = data.outputs[0].data.images
                        console.log(this.imageDalle)
                        this.createImagePopUp = true
                    }
                } catch (error) {
                    console.error("There was a problem with the fetch operation:", error);
                    break; // Exit the loop if there's an error
                }
            }

        },
        // Sending a POST request to the server with the image name.
        // async deleteFile() {

        //     var myHeaders = new Headers();
        //     // myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

        //     var urlencoded = new URLSearchParams();
        //     urlencoded.append("image_name", this.filename);

        //     var requestOptions = {
        //         method: "POST",
        //         headers: myHeaders,
        //         body: urlencoded,
        //         redirect: "follow"
        //     };

        //     const rawResponse = await fetch(
        //         process.env.VUE_APP_BASE_URL + '/api/deleteJpgConvertedImage',
        //         requestOptions
        //     );
        //     const response = await rawResponse.json();
        //     console.log(response)
        //     this.dalle_file = ''
        //     this.nftCategory = ''
        //     this.nftName = ''
        //     this.nftDescription = ''

        //     this.is_loading = false;
        // },
        generateImagePopUp() {
            this.createImagePopUp = true;
        },

        // Checking if the video title is not empty.
        validateVideoTitle() {
            if (this.videoTitle != '') {
                this.isValidVideoTitle = true;
            }
        },
        // A method that is called when the child component emits an event.
        getRecordedData(recordedData) {
            this.recordedData = recordedData;
        },
        // Saving the video record.
        saveVideoRecord() {
            if (this.videoTitle == '') {
                this.isValidVideoTitle = false;
                return false;
            }
            this.video = this.blobToFile(this.recordedData, this.videoTitle)
            this.recordedData = null;
            this.videoRecordPopupActive = false;
            this.videoTitle = '';
            this.isValidVideoTitle = true;
            this.$refs.refVideoRecord.player.record().reset();
        },
        // Converting the blob to a file.
        blobToFile(theBlob, fileName) {
            //A Blob() is almost a File() - it's just missing the two properties below which we will add
            theBlob.lastModifiedDate = new Date();
            theBlob.name = fileName;
            return theBlob;
        },
        // Resetting the video record.
        cancelVideoRecord() {
            this.recordedData = null;
            this.videoRecordPopupActive = false;
            this.videoTitle = '';
            this.isValidVideoTitle = true;
            this.$refs.refVideoRecord.player.record().reset();
        },
        // Setting the opacity and visibility of the videoUploadCard to 1 and visible.
        displayVideoUploadCard() {
            if (!this.$refs.videoUploadCard) return
            const videoUploadCardStyle = this.$refs.videoUploadCard.$el.style
            videoUploadCardStyle.setProperty('opacity', 1)
            videoUploadCardStyle.setProperty('visibility', 'visible')
        },
        // Setting the opacity and visibility of the videoUploadCard to 0 and hidden.
        hideVideoUploadCard() {
            if (!this.$refs.videoUploadCard) return
            const videoUploadCardStyle = this.$refs.videoUploadCard.$el.style
            videoUploadCardStyle.setProperty('opacity', 0)
            videoUploadCardStyle.setProperty('visibility', 'hidden')
        },
        // Inserting the emoji into the textarea.
        insert(emoji) {
            this.$refs.cpTextarea.$el.innerHTML += emoji
        },
  

        
     
        postImage() {

            this.is_loading = true;
            const medias = [...this.images]
            // const medias =  [this.imageDalle]
            console.log(medias)
            const postData = {
                author: this.$store.state.auth.userData.name,
                content: this.nftName,
                medias,
                video: this.video,
                uploadProgressbar: this.$refs.uploadProgressbar,
                hasOpenGraph: this.hasOpenGraph,
                ogMetaData: this.openGraphData,
                $vs: this.$vs,
                mintSupply: 1,
                isMinting: true,
                isListed: false,
                pricePerUnit: 0,
                mintSupplySold: 0,
                mintTokenId: this.mintTokenId,
                nftCategory: JSON.stringify(this.post_category)
            }
            this.$store.dispatch('newsfeed/createPost', postData).then((data) => {

                this.userDetails = data.data.createPost

                this.mintSupply = '';
                this.isMinting = true;
                this.input = ''
                this.prevImages = []
                this.images = []
                this.$refs.cpTextarea.$el.innerText = ''
                this.openGraphData = {}
                this.hasOpenGraph = false
                this.$refs.imageInput.value = ''
                this.video = ''
                this.is_loading = false;
                this.dalle_file = ''
                this.nftName = ''
                this.nftCategory = ''
                this.nftDescription = ''
                this.mintDisabled = true

            })
            this.$vs.notify({
                time: 2500,
                title: 'Success',
                text: 'Post Successful',
                iconPack: 'feather',
                icon: 'icon-check',
                position: 'top-right',
                color: 'success'
            })

            // this.deleteFile();
        },
        removeUploadImage(index) {
            this.prevImages.splice(index, 1)
            this.images.splice(index, 1)
            this.nftName = ''
            this.nftCategory = ''
            this.nftDescription = ''
        },
 
 
        updateInput() {
            this.input = this.$refs.cpTextarea.$el.innerText
            const urlRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig
            const matches = this.input.match(urlRegex)
            if (matches && matches.length > 0) {
                const lastMatched = matches[matches.length - 1]
                if (this.crawledUrl !== lastMatched) {
                    this.hasOpenGraph = true
                    this.crawledUrl = lastMatched
                    this.ogLoading = true
                    axios.post(`${process.env.VUE_APP_REST_API_ENDPOINT}/getOpenGraphJSON`, {
                        url: lastMatched
                    }).then(res => {
                        if (res.data.results) {
                            const {
                                ogDate,
                                ogDescription,
                                ogImage,
                                ogSiteName,
                                ogTitle,
                                ogUrl,
                                ogVideo,
                                requestUrl
                            } = res.data.results
                            this.openGraphData = {
                                ogDate,
                                ogDescription,
                                ogImage,
                                ogSiteName,
                                ogTitle,
                                ogUrl,
                                ogVideo,
                                requestUrl
                            }
                        }
                        this.ogLoading = false
                    })
                }
            }
        },
        goToWebsite() {
            window.open(this.openGraphData.requestUrl, '__blank')
        },

        setImage: function (file) {
            this.hasImage = true
            this.image = file
        },

    },
    components: {
        ProgressBar,
        EmojiPickerWrapper,
        // CpTextarea,
        // VideoRecord,
        ImageUploader,
        VuePerfectScrollbar,
        swiper,
        // swiperSlide

    }
}
</script>

<style lang="scss">
.blink_me {
    animation: blinker 1s linear infinite;
}

.flex-container-created-image {
    display: flex;
    justify-content: space-between;
    margin-top: 35px;

}

.dallbtn {
    /* From https://css.glass */
    // width: 130px;
    // height: 40px;
    color: #fff;
    border-radius: 6px;
    // padding: 10px 25px;
    padding: 0.75rem 2rem;
    // font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(255, 255, 255, .5),
        7px 7px 20px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
}

.btn-16 {
    border: none;
    color: #000;
}

.btn-16:after {
    position: absolute;
    content: "";
    width: 0;
    height: 100%;
    top: 0;
    left: 0;
    direction: rtl;
    z-index: -1;
    box-shadow:
        -7px -7px 20px 0px #fff9,
        -4px -4px 5px 0px #fff9,
        7px 7px 20px 0px #0002,
        4px 4px 5px 0px #0001;
    transition: all 0.3s ease;
}

.btn-16:hover {
    color: #000;
}

.btn-16:hover:after {
    left: auto;
    right: 0;
    width: 100%;
}

.btn-16:active {
    top: 2px;
}

// .dalle-image{
//     margin:3px;
//     padding:3px
// }

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.image-generator {
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 50px;
    padding: 0.75rem 1.25rem;
    // border: 2px solid #ebf0f7;
}

.image-generator:focus {
    border: 1px solid rgba(var(--vs-primary), 1) !important;
    box-shadow: 0 3px 10px 0 rgba(0, 0, 0, .15);
}

.custom-search .vs-inputx {
    border-radius: 50px !important;
}

.custom-search .vs-input.input-rounded-full .vs-input--input.hasIcon {}

.custom-search .vs-input.input-rounded-full .input-span-placeholder {}

.custom-search .vs-input--input.normal~.vs-input--icon.feather {}

.custom-search .vs-input--input:focus+.vs-input--placeholder {
    // -webkit-transform: none !important;
    // transform: none !important;
}

.custom-search .vs-input.input-rounded-full .vs-icon {}

.custom-add-label {
    margin-right: 0.3em;
}

input[type="file"] {
    display: none;
}

.post-input-headline {
    color: var(--grey);
}

.og-close-button {
    position: absolute !important;
    z-index: 1000;
    right: 37px;
    top: 158px;
}

.og-container:hover {
    opacity: 0.5;
}

.og-img {
    object-fit: cover;
    width: 100%;
    max-height: 300px;
}

.surprise {
    display: flex;
    flex-direction: row-reverse;
}

.vs-button.surprise-me-btn {
    background-color: #4263a3 !important;
    border: 1px solid #4263a3 !important;
}

.vs-button.ai-art-btn {
    background-color: #4263a3 !important;
    border: 1px solid #4263a3 !important;
    border-radius: 0 !important;
}

.art-tag {
    padding: 0.28571rem 0.71429rem !important;
    // color: #f00 !important;
    color: #555 !important;
    font-size: .875rem !important;
    border: solid 1px transparent !important;
    border-color: rgba(0, 0, 0, 0.2) !important;
    // border-color: #f00 !important;
    // background-color: rgba(255, 0, 0, 0.1) !important;
    // background-color: #eee !important;
    border-radius: 50px !important;
    margin-top: 12px;
    display: inline-block;
    text-decoration: none;
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    margin-bottom: 10px;
}

.scroll-area--customizer {
    height: calc(100% - 5rem);

    &:not(.ps) {
        overflow-x: auto;
    }
}

.ss-width {
    width: auto !important;
}

// Custom scrollbar
#scrollbar {
    scrollbar-width: thin;
}

/* width */
#scrollbar::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/* Track */
#scrollbar::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
}

/* Handle */
#scrollbar::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

/* Handle on hover */
#scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
}

// Custom scrollbar

.og-loading {
    width: 100%;
    height: 300px;
}

.og-card {
    min-height: 300px;
}

.img-close-button {
    position: relative;
    top: 17px;
    left: 96px;
    height: 0;
    cursor: pointer;
}

.hoverCard {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    // width: 300px;
    margin-top: 2px;
    width: 200px;
    z-index: 10000 !important;
    transition: visibility 0.1s linear, opacity 0.1s linear;
}

.post-card.vs-con-loading__container {
    overflow: unset !important;
}

.img-btn i {
    margin-right: 0px !important;

}

.img-btn .vs-button {
    border: 1px solid !important;
    border-color: rgba(var(--vs-dark), .2) !important;
    padding: 0.657rem 1.75rem !important;
}

.vs-button-line .vs-button-linex {
    height: 1px !important;
}

.mint-nft-btn {
    border-radius: 0px !important;
    padding: 0.5rem 2rem !important;
}

.mint-nft-btn.vs-button-border:hover {
    background-color: transparent !important;
}

.rotate-btn {
    box-shadow: none !important;
    border-radius: 50% !important;
    // background: rgba(var(--vs-success),.08) !important;
    background-color: #ddd !important;
}

.rotate-btn .feather {
    // color: #28a745 !important;
    color: #2c2c2c !important;
}

.rotate-btn.includeIconOnly {
    width: 32px !important;
    height: 32px !important;
}

// .img-btn .con-vs-card{
//   margin-top: 2px !important;
// }
</style><style>
.mint-nft.con-vs-popup .vs-popup {
    width: 600px !important;
}

.dalle-image.con-vs-popup .vs-popup--content {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.dalle-image.con-vs-popup .vs-popup--header {
    background-color: transparent !important;
}

.image-dalle {
    height: 220px;
    object-fit: cover;
    object-position: top;
}

.mic-on .feather {
    color: #28a745 !important;
}

.mic-off .feather {
    color: #dc123e !important;
}

/* loading-text */
.text-loader {
    /* position: absolute; */
    font-weight: 600;
    color: #dc123e;
    width: 10.2rem;
}

.text-loader:after {
    content: "Generating Art";
    /* font-weight: bold; */
    animation: text-loading 3s infinite;
}

@keyframes text-loading {
    0% {
        content: "Generating Art"
    }

    25% {
        content: "Generating Art."
    }

    50% {
        content: "Generating Art.."
    }

    75% {
        content: "Generating Art..."
    }
}

.vld-overlay .vld-background {
    opacity: 0.9 !important;
}

/* End loading-text */

@media (min-width: 992px) {
    .dalle-image.con-vs-popup .vs-popup {
        width: 734px !important;
        margin-left: 5.5%;
    }

    .image-generator {
        width: 95% !important;
    }
}

@media screen and (min-width: 900px) and (max-width: 1440px) {
    .dalle-image.con-vs-popup .vs-popup {
        width: 663px !important;
        margin-left: 6%;
    }
}

@media screen and (min-width: 800px) and (max-width: 1280px) {
    .dalle-image.con-vs-popup .vs-popup {
        width: 583px !important;
        margin-left: 6.8%;
    }
}

@media only screen and (max-width: 600px) {
    .img-btn .vs-button {
        padding: 0.4rem 0.75rem !important;
    }
}
</style><style lang="scss" scoped>
.post-card.vs-con-loading__container {
    overflow: unset !important;
}
</style>
