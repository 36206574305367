<template>
<div class="suggested-follows">
    <!-- <vx-card title="Suggested Follows" class="mt-base people-to-follow"> -->
    <!-- FRIENDS LIST -->
    <p v-if="suggestedFollows.length === 0 && !isLoading">We haven't found anyone yet</p>
    <ul class="friend-suggesions-list" v-if="!isLoading">
        <li class="friend-suggestion flex justify-between items-center mb-4" v-for="(friend, index) in this.suggestedFollows" :key="index">
            <div class="flex items-center leading-tight cursor-pointer" @click="goToProfilePage(friend)">
                <template v-if="friend.isPartner">
                    <AvatarHolder :hideProfileHoverCard="true" :displayName='friend.displayName' :profileData="friend" :partnerData="friend.partnerData" :photoURL="friend.photoURL" :size="'40px'" />
                </template>
                <template v-else>
                    <AvatarHolder :hideProfileHoverCard="true" :displayName='friend.displayName' :profileData="friend" :photoURL="friend.photoURL" :size="'40px'" />
                </template>
                <div class="ml-2">
                    <p class="font-large font-semibold suggested-people-displayname">{{ friend.displayName }}</p>
                    <!-- <small>{{ friend.studyStatus }}</small><feather-icon class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-primary rounded-full text-white"></feather-icon> -->
                    <span v-if="!friend.isPartner " class="text-base">{{ friend.studyStatus }}</span>
                    <span v-if="friend.partnerData" class="text-base">{{ friend.partnerData.partnerType | partnerTypeFormatter }}</span>
                    <feather-icon v-if="friend.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-success rounded-full text-white"></feather-icon>
                </div>
            </div>
            <!--
            <vs-button radius class="z-0 mr-1 ml-2" size="small" color="primary" @click="followUserInSuggestedList(friend, index)">Follow</vs-button>
             -->
        </li>
    </ul>
    <div style="min-height: 400px" v-else>
        <Loading />
    </div>
    <template slot="footer">
        <!-- <vs-button icon-pack="feather" icon="icon-plus" class="w-full">Load More</vs-button> -->
    </template>
    <!-- </vx-card> -->
</div>
</template>

<script>
import AvatarHolder from './AvatarHolder'
import Loading from './Loading'

export default {
    name: 'SuggestedFollows',
    components: {
        AvatarHolder,
        Loading
    },
    props: {
        isCourseFeed: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            isLoading: true
        }
    },
    computed: {
        suggestedFollows() {
            let suggestedFollows = this.$store.state.follow.suggestedFollows;
            let followers_with_image = []
            let followers_without_image = []
            for (let index = 0; index < suggestedFollows.length; index++) {
                const element = suggestedFollows[index];
                if (element.photoURL.split('/')[element.photoURL.split('/').length - 1] !== 'default_dp.png') {
                    followers_with_image.push(element);
                } else {
                    followers_without_image.push(element);
                }
            }
            return followers_with_image.concat(followers_without_image);
        }
    },
    mounted() {
        if (this.isCourseFeed) {
            this.$store.dispatch('follow/fetchCourseFeedSuggestFollow', {
                id: this.$route.params.id
            }).then(() => {
                this.isLoading = false
            })
        } else {
            this.$store.dispatch('follow/fetchSuggestedFollows', {
                channelID: this.$store.state.channel.PUBLIC_CHANNEL.channel_id,
                channelType: 'PUBLIC'
            }).then(() => {
                this.isLoading = false
            })
        }
    },
    methods: {
        followUserInSuggestedList(friend, index) {
            console.log(friend)
            this.$store.dispatch('follow/followUserInSuggestedList', {
                friend,
                index
            })
           
            if (friend.enablePostNotification !== false) {

                this.$store.dispatch('newsfeed/sendMail', {
                    user_name: this.$store.state.auth.userData.name,
                    activity_user: friend.displayName,
                    activity: 'FOLLOW',
                    template_code: 'send_notification',
                    activity_user_mail: friend.email,
                    post_content: null,
                    activity_user_image_url: this.$store.state.auth.userData.photoURL,
                    post_image_url: null,
                    profile_url: `https://mypals.app/profile/${this.$store.state.auth.userData.name}`,
                    post_url: null,

                })
            }
        },
        goToProfilePage(friend) {
            if (!friend.isPartner) {
                this.$router.push(`/profile/${friend.name}`).catch(() => {})
            } else {
                this.$router.push(`/partner/${friend.name}`).catch(() => {})
            }
        }
    }
}
</script>

<style lang="css" scoped>
@media (min-width: 992px) {
    .suggested-follows {
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 300px;
        /* position: relative; */
    }
}

/* .vs-con-loading__container {
    overflow: hidden !important;
} */
.suggested-people-displayname {
    word-break: break-all;
    word-break: break-word;
    white-space: normal !important;
}
</style>
