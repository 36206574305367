<template>
  <div>
    <vs-popup title="Edit Post" :active.sync="isActive">
      <div class="post-header flex">
        <div class="w-full">
          <CpTextarea ref="editPostTextarea" @input="updateInput"/>
        </div>
      </div>
      
      <div class="flex flex-wrap  mt-4">
        <div :key="index" v-for="(image, index) in feed.mediaFiles">
          <feather-icon 
            icon="XCircleIcon"
            class="img-close-button"
            svgClasses="h-7 w-7 mb-1 text-primary"
            @click="removeUploadImage(index)"
            ></feather-icon>
          <img :src="image" width="110" height="110" class="object-cover" alt="upload-image"/>
        </div>
      </div>

      <div class="flex">
        <div class="flex-1">
          <div class="flex justify-end">
            <EmojiPickerWrapper v-on:handleInsertEmoji="insert" />
            <vs-button @click="onSubmitPost" color="primary" size="medium" type="filled" :disabled="editDisabled">Edit Post</vs-button>
            <vs-button class="ml-2" @click="cancelPost" type="border" size="medium" >Cancel</vs-button>
          </div>
        </div>
      </div>

    </vs-popup>
  </div>
</template>

<script>
import CpTextarea from '@/components/CpTextarea'
import EmojiPickerWrapper from './EmojiPickerWrapper'

export default {
  data () {
    return {
      input: '',
      feed: {},
      feedIndex: 0,
      isActive: false,
      editDisabled: true
    }
  },
  computed: {
    validateForm () {
      return !this.errors.any() && this.$refs.editPostTextarea.$el.innerText !== '' 
    }
  },
  methods: {
    insert (emoji) {
      this.$refs.editPostTextarea.$el.innerText += emoji
      this.input = this.$refs.editPostTextarea.$el.innerText
      this.editDisabled = false
    },
    onSubmitPost (e) {
      e.preventDefault()
      if (!this.validateForm) return
      this.input = this.$refs.editPostTextarea.$el.innerText
      this.$store.dispatch('newsfeed/updatePost', { 
        id: this.feed.id,
        content: this.input,
        medias: this.feed.medias,
        feedIndex: this.feedIndex
      }).then(() => {
        this.input = ''
        this.isActive = false
        this.$emit('postEditFinished')
      })
    },
    removeUploadImage (index) {
      this.$store.commit('newsfeed/SPLICE_CURRENT_POST_MEDIAS', index)
      this.editDisabled = false
    },
    cancelPost () {
      this.input = ''
      this.isActive = false
    },
    updateInput () {
      this.input = this.$refs.editPostTextarea.$el.innerText
      this.editDisabled = false
    }
  },
  components: {
    EmojiPickerWrapper,
    CpTextarea
  },
  mounted () {
    this.$refs.editPostTextarea.$el.innerText = this.feed.content
    this.$refs.editPostTextarea.createHashtagElement()
  }
}
</script>

<style lang="css" scoped>
.custom-add-label {
  margin-right: 0.3em;
}
input[type="file"] {
  display: none;
}

.img-close-button {
  position: relative;
  top: -45px;
  left: 120px;
  height: 0;
  cursor: pointer;
}

</style>