<template>
<div class="my-followers">
   <!-- <vx-card title="My Followers" class="mt-base people-to-follow"> -->
      <!-- FRIENDS LIST -->
      <p v-if="myFollows.length === 0">We haven't found anyone yet</p>
      <ul class="friend-suggesions-list mt-2">
        <div v-for="(friend, index) in myFollows" :key="index" >
            <li v-if="friend!=null" class="friend-suggestion flex items-center mb-4" >
                <div class="flex items-center leading-tight cursor-pointer" @click="goToProfilePage(friend)">
                  <template v-if="friend.isPartner">
                    <AvatarHolder :hideProfileHoverCard="true" :displayName='friend.displayName' :profileData="friend" :partnerData="friend.partnerData" :photoURL="friend.photoURL" :size="'40px'" />
                  </template>
                  <template v-else>
                    <AvatarHolder :hideProfileHoverCard="true" :displayName='friend.displayName' :profileData="friend" :photoURL="friend.photoURL" :size="'40px'" />
                  </template>
                  <div class="ml-2">
                    <p class="font-large font-semibold">{{ friend.displayName }}</p>
                    <!-- <small>{{ friend.studyStatus }}</small><feather-icon class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-primary rounded-full text-white"></feather-icon> -->
                    <span v-if="!friend.isPartner " class="text-base">{{ friend.studyStatus }}</span>
                    <span v-if="friend.partnerData" class="text-base">{{ friend.partnerData.partnerType | partnerTypeFormatter }}</span>
                    <feather-icon v-if="friend.isUniEmailVerify" class="ml-1" icon="CheckIcon" svgClasses="h-3 w-3 bg-success rounded-full text-white"></feather-icon>
                  </div>
                </div>
            </li>
          </div>
      </ul>
      <template slot="footer">

      </template>
  <!-- </vx-card> -->
</div>
</template>

<script>
import AvatarHolder from './AvatarHolder'

export default {
  name: 'MyFollowers',
  components: {
    AvatarHolder
  },
  props: {
    isProfile: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    myFollows () {
      if (this.isProfile) {
        let userInstances = this.$store.state.profile.followers.items.map(f => f.followerInstance)
        let followers_with_image = []
        let followers_without_image = []
        for (let index = 0; index < userInstances.length; index++) {
          const element = userInstances[index];
          if(element.photoURL.split('/')[element.photoURL.split('/').length-1] !== 'default_dp.png'){
            followers_with_image.push(element);
          }else{
            followers_without_image.push(element);
          }
        }
        return followers_with_image.concat(followers_without_image);
      } else {
        const filter = this.$store.state.follow.followers.items.filter(f => { return f.followerInstance !== null})
        let userInstances = filter.map(f => f.followerInstance)
        let followers_with_image = []
        let followers_without_image = []
        for (let index = 0; index < userInstances.length; index++) {
          const element = userInstances[index];
          if(element.photoURL.split('/')[element.photoURL.split('/').length-1] !== 'default_dp.png'){
            followers_with_image.push(element);
          }else{
            followers_without_image.push(element);
          }
        }
        return followers_with_image.concat(followers_without_image);
      }
    }
  },
  methods: {
    goToProfilePage (friend) {
      if (!friend.isPartner) {
        this.$router.push(`/profile/${friend.name}`).catch(() => {})
      } else {
        this.$router.push(`/partner/${friend.name}`).catch(() => {})
      }
    }
  },
  created () {
    this.$store.dispatch('follow/fetchFollowers')
  }
}
</script>

<style scoped>

</style>