<template>
  <div>
      <div  v-if="!isLoading" >
     
         <EditPostForm ref="editPostForm" />
        <div v-for="(feed, feedIndex) in newsfeed" :key="feedIndex">
          <Post 
            :actor="feed.actor"
            :action="feed.action"
            :feed="feed.postRef" :feedIndex="feedIndex" 
            :displayComment="true"
            :displayPostOption="true"
            :displayLikeAndShare="true"
            :displayShare="displayShare"
            :displayLikeAndCommentCount="true"
            @toggleEditPostForm="toggleEditPostForm(feed.postRef, feedIndex)" />
        </div>
      </div>
      
      <div class="" v-else>
        <div class="loading">
          <div class="effect-1 effects"></div>
          <div class="effect-2 effects"></div>
          <div class="effect-3 effects"></div>
        </div>
      </div>
      
  </div>
</template>

<script> 
import Post from './Post/Post'
import EditPostForm from '@/layouts/components/coursepal/EditPostForm'

export default {
  name: 'Newsfeed',
  components: {
    Post,
    EditPostForm
  },
  data () {
    return {
      universityName: []
    }
  },
  props: ["displayShare"],
  computed: {
    newsfeed () {
      return this.$store.state.newsfeed.feeds
    },
    isLoading () {
      return this.$store.state.newsfeed.isLoading
    }
  },
  created(){
    console.log(this.$store.state.newsfeed.feeds)
    this.$vs.loading.close()
  },
  
  methods: {
    disablePostEditor () {
      this.isEditorActive = false
    },
    deletePost (event, feedIndex) {
      this.$store.dispatch('newsfeed/deletePost', feedIndex)
    },
    toggleEditPostForm (feed, feedIndex) {
      this.$store.dispatch('newsfeed/setCurrentPost', feed)
      this.$refs.editPostForm.feed = feed
      this.$refs.editPostForm.feedIndex = feedIndex
      this.$refs.editPostForm.isActive = true
      this.$refs.editPostForm.$refs.editPostTextarea.$el.innerText = feed.content
      this.$refs.editPostForm.$refs.editPostTextarea.createHashtagElement()
    }
  }
}
</script>

<style lang="css" scoped>
.profile-sub-heading {
  color: #3952A6;
}
.fade-enter-active, .fade-leave-active {
    transition: opacity .5s !important;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0 !important;
}

</style>