<template>
<div id="profile-page">
    <!-- <div class="vx-row">
            <div class="vx-col w-full">
               <Tabs/>
            </div>
        </div> -->

    <!-- COL AREA -->
    <div class="vx-row">
        <!-- COL 1 -->
        <div class="vx-col w-full lg:w-1/4 lg:order-1 order-2 hidden-sm hidden-md hidden-ip">
            <AboutUser v-if="!activeUserData.isPartner" />
          
            <!--   <TrendingNow /> -->

            <!-- <MyFeeds :author="activeUserData.name" :isUniEmailVerify="isUniEmailVerify" /> -->
            <!-- <PictureHolder
                  :isLoading="isPictureHolderLoading"
                  v-on:pictures-loaded="setPicturesLoaded"
                /> -->

        </div>
        <!-- COL 2 -->
        <div class="vx-col w-full sm:w-full md:w-full lg:full xl:w-1/2 lg:order-2 order-1 p-0">
            <!-- <WelcomeFeedsInfo v-if="!isIntroClosed" /> -->
            <CreatePost parentType="connectpage" :userImage="activeUserData.photoURL" />
            <!-- <PostInputForm parentType="connectpage" /> -->
            <Newsfeed :displayShare="true" />
        </div>
        <!-- COL 3 -->
        <div class="vx-col w-full lg:w-1/4 lg:order-3 order-3 hidden-sm hidden-sm hidden-md hidden-ip">
            <!-- LATEST PHOTOS -->
          
            <!-- <SuggestedFollows />
                <MyFollows />
                <MyFollowers /> -->

            <div class="mt-3">
                <vx-card title="People to follow" class="vt-step-5">
                    <div class="mypals-mobile">
                        <b-tabs pills content-class="mt-3">
                            <b-tab title="Suggested People">
                                <SuggestedFollows />
                            </b-tab>
                            <b-tab title="My Follows">
                                <MyFollows />
                            </b-tab>
                            <b-tab title="My Followers">
                                <MyFollowers />
                            </b-tab>
                        </b-tabs>
                    </div>
                </vx-card>
            </div>

        </div>
    </div>

    <div class="vx-row">
        <div class="vx-col w-full">
            <div class="flex justify-center mt-base">
                <div id="button-load-more-posts" class="vs-con-loading__container"></div>
            </div>
        </div>
    </div>

    <div>
        <!-- <Share /> -->
    </div>

    <div v-if="!isIntroClosed">
        <v-tour name="myTour" :steps="steps" class="hidden-sm"></v-tour>
        <v-tour-mobile name="myTour" :steps="steps" class="sm:hidden md:hidden"></v-tour-mobile>
    </div>
    <!--    <install-my-pals-app />  -->
    <!-- <div v-if="downloadApp">
        <InstallMyPalsApp />
    </div> -->

</div>
</template>

<script>
//import Tabs from '@/layouts/components/coursepal/Tabs'


import AboutUser from '@/layouts/components/coursepal/AboutUser'
import CreatePost from '@/layouts/components/coursepal/CreatePost'

import PostInputForm from '@/layouts/components/coursepal/PostInputForm'
import Newsfeed from '@/layouts/components/coursepal/Newsfeed'
import SuggestedFollows from '@/layouts/components/coursepal/SuggestedFollows'

import MyFollows from '@/layouts/components/coursepal/MyFollows'
import MyFollowers from '@/layouts/components/coursepal/MyFollowers'

// import MyFeeds from '@/layouts/components/coursepal/MyFeeds'
//import PictureHolder from '@/layouts/components/coursepal/PictureHolder'
//import Share from '@/layouts/components/coursepal/Share'
//import WelcomeFeedsInfo from '@/layouts/components/coursepal/WelcomeFeedsInfo'
//import TrendingNow from '@/layouts/components/coursepal/TrendingNow'
import VTour from '@/layouts/components/coursepal/VTour'
import VTourMobile from '@/layouts/components/coursepal/VTourMobile'
import PostInputPopup from '../../layouts/components/coursepal/mobile/PostInputPopup.vue'
//  import InstallMyPalsApp from '@/views/pages/InstallMyPalsApp'

export default {
    name: 'ConnectPage',
    data() {
        return {
            isNavOpen: false,
            wasSidebarOpen: null,
            isPictureHolderLoading: false,
            componentKey: 0,
            downloadApp: false
        }
    },
    computed: {
        activeUserData() {
            return this.$store.state.auth.userData
        },
        isUniEmailVerify() {
            return this.$store.state.auth.isUniEmailVerified
        },
        isIntroClosed() {
            return this.$store.state.auth.userData.isIntroClosed
        },
        isFirstLogin() {
            return this.$store.state.auth.userData.isFirstLogin
        }
    },
    methods: {

        // Checking if the app is installed on the device.
        appDownload() {
            if ((navigator.userAgent.match(/iPhone/i)) || (navigator.userAgent.match(/iPod/i)) || (navigator.userAgent.match(/iPad/i))) {
                this.downloadApp = true
            }
            if (navigator.userAgent.match(/android/i)) {

               this.downloadApp = true
            }
        },
        setPicturesLoaded() {
            this.isPictureHolderLoading = false
        },
        handleScroll() {
            if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
                this.$vs.loading({
                    container: '#button-load-more-posts',
                })
                this.$store.dispatch('newsfeed/fetchNextPage', {
                    channelID: this.$store.state.channel.currentChannel.channel_id
                }).then(() => this.$vs.loading.close('#button-load-more-posts > .con-vs-loading'))
            }
        },
        // Checking if the app is installed on the device.
        // async checkUpInstall() {
        //     console.log('testing')
        //     const relatedApps = await navigator.getInstalledRelatedApps();
        //     relatedApps.forEach((app) => {
        //         console.log(app.id, app.platform, app.url);
        //     });
        // }

    },
    components: {
        //  Tabs,
        CreatePost,
        PostInputForm,
        Newsfeed,
        SuggestedFollows,

        MyFollows,
        MyFollowers,

        // MyFeeds,
        // PictureHolder,
        //Share,
        AboutUser,
        // WelcomeFeedsInfo,
        // TrendingNow,
        VTour,
        VTourMobile,
        //  InstallMyPalsApp
    },
    mounted() {
        this.wasSidebarOpen = this.$store.state.reduceButton
        this.$store.commit('TOGGLE_REDUCE_BUTTON', true)

        this.$store.dispatch('newsfeed/fetchPosts', {
            channel: this.$store.state.channel.PUBLIC_CHANNEL,
            type: 'PUBLIC'
        })

  
        this.$store.dispatch('newsfeed/fetchMyFeeds').then(() => {
            this.isPictureHolderLoading = false
        })

        this.$store.dispatch('follow/fetchFollowers')
        this.$store.dispatch('follow/fetchFollowings')

    },
    created() {
         
        window.addEventListener('scroll', this.handleScroll)
        // this.checkUpInstall();
        this.appDownload();
        

    },
    beforeDestroy() {
        if (!this.wasSidebarOpen) this.$store.commit('TOGGLE_REDUCE_BUTTON', false)
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
    },

}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/pages/profile.scss";

.vx-card .vx-card__collapsible-content.vs-con-loading__container {
    overflow: unset !important;
}

#button-load-more-posts {
    width: 200px;
    height: 80px;
    justify-content: center;
    align-items: center;
}
</style>
